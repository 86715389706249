<h2 mat-dialog-title>{{ title | translate }}</h2>
<mat-dialog-content>{{ message | translate }}</mat-dialog-content>
<mat-dialog-actions
    [class.justify-content-between]="cancelText"
    [class.justify-content-end]="!cancelText"
>
    @if (cancelText) {
    <button class="btn btn-light" mat-dialog-close>
        {{ cancelText | translate }}
    </button>
    } @if (confirmText) {
    <button class="btn btn-danger" [mat-dialog-close]="true" cdkFocusInitial>
        {{ confirmText | translate }}
    </button>
    } @if (infoText) {
    <button class="btn btn-primary" [mat-dialog-close]="true" cdkFocusInitial>
        {{ infoText | translate }}
    </button>
    }
</mat-dialog-actions>
