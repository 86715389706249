import { catchError, Observable, of, Subscription, switchMap, tap } from "rxjs";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Profile } from "../../../../profile/store/profile.entity";
import { AppointmentInfoService } from "../../../providers/appointment-info.service";
import {
    DATE_TIME_FORMAT_FRONTEND,
    TimeHelperService,
} from "../../../../utils/time-helpers/time-helper.service";
import { AppointmentInfoType } from "../appointment-info";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { map, filter } from "rxjs";
import { StaticUtils } from "../../../../utils/static-utils";
import {
    MultiUserAppointment,
    OrderByStartDate,
} from "../../../store/appointment/multi-user-appointment.entity";
import { Conference } from "../../../../conference-v2/store/conference.model";
import {
    AppointmentStatus,
    appointmentStatus,
} from "../../../store/appointment/appointment-status";
import { ProfileSelectService } from "../../../../profile/providers/profile-select.service";
import { VonageConfigurationService } from "../../../../../providers/vonage-configuration/vonage-configuration.service";
import { RestrictedFeatureRouteMap } from "../../../../../entities/institution/institution.model";
import { MultiUserAppointmentService } from "../../../providers/multi-user-appointment.service";
import { ConfirmModalComponent } from "../../../../modal/confirm-modal/confirm-modal.component";
import { ConfirmModalData } from "../../../../modal/confirm-modal/confirm-modal.data";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { fetchMultiUserAppointmentsAction } from "../../../store/appointment/multi-user-appointment.action";
import moment from "moment";
import { CancelAppointmentCompletedAction } from "../../../store/one-time-appointment/appointment.action";
import { CancelAppointmentDTO } from "../../../providers/cancel-appointment.type";

@Component({
    selector: "app-multi-user-appointment-info",
    templateUrl: "./multi-user-appointment-info.component.html",
    styleUrls: ["./multi-user-appointment-info.component.scss"],
    standalone: false,
})
export class MultiUserAppointmentInfoComponent implements OnInit, OnDestroy {
    @Input() appointment: MultiUserAppointment;
    @Input() appointmentInfoType: AppointmentInfoType;
    currentProfileId: number;
    participants$: Observable<Profile[]>;
    participants: Profile[];
    tanLoginLink = `${window.location.origin}/tan-login?tan=`;
    isAppointmentToday: boolean;
    upComingAppointmentInfoType = AppointmentInfoType.UPCOMING;
    readonly defaultVisibleParticipants = 3;
    visibleParticipants: number = this.defaultVisibleParticipants;
    conference: Conference;
    callStarted = false;
    readonly canceledAppointment = appointmentStatus.canceled;
    vonageLoading$: Observable<boolean>;
    appointmentInfo = {
        participants: [],
    };
    ovsProviderSubscription: Subscription = null;
    canDeleteAppointment = !RestrictedFeatureRouteMap.hasOwnProperty(
        this.profileSelectService.getCurrentProfile().institution
    );

    constructor(
        private appointmentInfoService: AppointmentInfoService,
        private multiUserAppointmentService: MultiUserAppointmentService,
        private profileSelectService: ProfileSelectService,
        private vonageConfig: VonageConfigurationService,
        private dialog: MatDialog,
        private store: Store
    ) {
        this.vonageLoading$ = this.vonageConfig.loading$.pipe(
            filter(
                (loading) =>
                    loading &&
                    this.appointment.id === this.vonageConfig.appointmentId
            )
        );
    }

    ngOnInit() {
        const currentProfileId =
            this.appointmentInfoService.getCurrentProfileId();
        this.participants$ = this.appointmentInfoService
            .loadMUCParticipants(this.appointment, currentProfileId)
            .pipe(
                map((participants: Profile[]) => {
                    this.participants = StaticUtils.sortAscending(
                        participants,
                        "fullName"
                    );
                    return this.participants;
                })
            );
        this.isAppointmentToday = TimeHelperService.isToday(
            this.appointment?.startDateTime
        );

        this.appointmentInfoService
            .loadConference(this.appointment.conferenceId)
            .subscribe((conference: Conference) => {
                this.conference = conference;
            });
    }

    ngOnDestroy(): void {
        this.ovsProviderSubscription?.unsubscribe();
    }

    toggleTooltip(tooltip: NgbTooltip): void {
        tooltip.open();
        setTimeout(() => {
            tooltip.close();
        }, 2000);
    }

    toggleParticipants(allParticipants: number): void {
        if (this.visibleParticipants === this.defaultVisibleParticipants) {
            this.visibleParticipants = allParticipants;
        } else {
            this.visibleParticipants = this.defaultVisibleParticipants;
        }
    }

    start() {
        const profile = this.profileSelectService.getCurrentProfile();
        const otherProfiles = this.participants;
        this.vonageConfig.start(
            this.conference,
            this.appointment,
            profile,
            otherProfiles
        );
    }

    openDeleteAppointment() {
        this.dialog
            .open<ConfirmModalComponent, ConfirmModalData>(
                ConfirmModalComponent,
                {
                    data: {
                        title: "MODAL.DELETE-APPOINTMENT.TITLE",
                        message: "MODAL.DELETE-APPOINTMENT.MESSAGE",
                        confirmText: "MODAL.DELETE-APPOINTMENT.BUTTON.DELETE",
                        cancelText: "MODAL.DELETE-APPOINTMENT.BUTTON.CANCEL",
                    },
                    minHeight: "33%",
                    minWidth: "33%",
                    position: { top: "15%" },
                }
            )
            .afterClosed()
            .pipe(
                filter((result) => !!result),
                switchMap(() =>
                    this.multiUserAppointmentService.removeAppointment(
                        this.appointment.id
                    )
                ),
                map(
                    () =>
                        ({
                            title: "MODAL.DELETE-APPOINTMENT.SUCCESS.TITLE",
                            message: "MODAL.DELETE-APPOINTMENT.SUCCESS.MESSAGE",
                            infoText:
                                "MODAL.DELETE-APPOINTMENT.SUCCESS.BUTTON.OK",
                        } satisfies ConfirmModalData)
                ),
                catchError(() => {
                    return of({
                        title: "MODAL.DELETE-APPOINTMENT.ERROR.TITLE",
                        message: "MODAL.DELETE-APPOINTMENT.ERROR.MESSAGE",
                        infoText: "MODAL.DELETE-APPOINTMENT.ERROR.BUTTON.OK",
                    } satisfies ConfirmModalData);
                }),
                tap((data) => {
                    this.dialog.open<ConfirmModalComponent, ConfirmModalData>(
                        ConfirmModalComponent,
                        {
                            data,
                            minHeight: "33%",
                            minWidth: "33%",
                            position: { top: "15%" },
                        }
                    );
                }),
                tap(() =>
                    this.store.dispatch(
                        new CancelAppointmentCompletedAction({
                            appointment: this.appointment,
                            profile:
                                this.profileSelectService.getCurrentProfile(),
                        } satisfies CancelAppointmentDTO)
                    )
                )
            )
            .subscribe(() => this.refreshUpcomingAppointments());
    }

    private refreshUpcomingAppointments() {
        const params = {
            orderByStartDate: OrderByStartDate.ASCENDING,
            appointmentStatus: Object.keys(appointmentStatus)
                .filter((status) => status !== AppointmentStatus.CANCELED)
                .join(","),
            minStartDateTime: moment()
                .startOf("day")
                .format(DATE_TIME_FORMAT_FRONTEND),
        };
        this.store.dispatch(fetchMultiUserAppointmentsAction({ params }));
    }
}
