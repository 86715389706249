import { Component, inject, OnInit } from "@angular/core";
import { ConfirmModalData } from "./confirm-modal.data";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
    selector: "app-confirm-modal",
    templateUrl: "confirm-modal.component.html",
    standalone: false,
})
export class ConfirmModalComponent {
    private readonly data = inject<ConfirmModalData>(MAT_DIALOG_DATA);
    readonly title = this.data.title.trim();
    readonly message = this.data.message.trim();
    readonly infoText = this.data.infoText?.trim();
    readonly confirmText = this.data.confirmText?.trim();
    readonly cancelText = this.data.cancelText?.trim();
}
