import { Action } from "@ngrx/store";
import { Appointment } from "./appointment.entity";
import { CreateTanAppointmentDTO } from "../../providers/create-tan.types";
import { CancelAppointmentDTO } from "../../providers/cancel-appointment.type";

export const APPOINTMENTS_INITIAL_RECEIVED_FAILED =
    "APPOINTMENTS_INITIAL_RECEIVED_FAILED";
export const APPOINTMENTS_INITIAL_RECEIVED_SUCCESSFUL =
    "APPOINTMENTS_INITIAL_RECEIVED_SUCCESSFUL";
export const APPOINTMENT_RECEIVED_SUCCESSFUL =
    "APPOINTMENT_RECEIVED_SUCCESSFUL";
export const APPOINTMENT_RECEIVED_FAILED = "APPOINTMENT_RECEIVED_FAILED";
export const APPOINTMENT_FINISHED_SUCCESSFUL =
    "APPOINTMENT_FINISHED_SUCCESSFUL";

export const APPOINTMENT_CREATE_TAN = "CREATE_TAN";
export const APPOINTMENT_CREATE_TAN_APPOINTMENT_COMPLETED =
    "APPOINTMENT_CREATE_TAN_APPOINTMENT_COMPLETED";
export const APPOINTMENT_CREATE_TAN_APPOINTMENT_FAILED =
    "APPOINTMENT_CREATE_TAN_APPOINTMENT_FAILED";

export const APPOINTMENT_CANCEL_COMPLETED = "APPOINTMENT_CANCEL_COMPLETED";
export const APPOINTMENT_CANCEL_FAILED = "APPOINTMENT_CANCEL_FAILED";

export const APPOINTMENT_REMOVE_ALL = "APPOINTMENT_REMOVE_ALL";

export const APPOINTMENT_ADD = "APPOINTMENT_ADD";
export const APPOINTMENT_CREATE = "APPOINTMENT_CREATE";

export const APPOINTMENT_CONFERENCE_CREATE = "APPOINTMENT_CONFERENCE_CREATE";

export interface AppointmentAction extends Action {
    readonly type: string;
    payload:
        | Appointment
        | Appointment[]
        | CreateTanAppointmentDTO
        | null
        | CancelAppointmentDTO;
    errors: string[] | null;
}

export class AppointmentsInitialReceivedSuccessfulAction
    implements AppointmentAction
{
    readonly type = APPOINTMENTS_INITIAL_RECEIVED_SUCCESSFUL;
    errors = null;

    constructor(public payload: Appointment[]) {}
}

export class AppointmentsInitialReceivedFailedAction
    implements AppointmentAction
{
    readonly type = APPOINTMENTS_INITIAL_RECEIVED_FAILED;
    readonly payload = null;

    constructor(readonly errors: string[]) {}
}

export class AppointmentsRemoveAllAction implements AppointmentAction {
    readonly type = APPOINTMENT_REMOVE_ALL;
    errors = null;
    payload = null;
}

export class AppointmentAddAction implements AppointmentAction {
    readonly type = APPOINTMENT_ADD;
    errors = null;

    constructor(public payload: Appointment) {}
}

export class AppointmentCreateAction implements AppointmentAction {
    readonly type = APPOINTMENT_CREATE;
    errors = null;

    constructor(public payload: CreateTanAppointmentDTO) {}
}

export class AppointmentCreateTanAction implements AppointmentAction {
    readonly type = APPOINTMENT_CREATE_TAN;
    errors = null;

    constructor(public payload: CreateTanAppointmentDTO) {}
}

export class AppointmentConferenceCreateAction implements AppointmentAction {
    readonly type = APPOINTMENT_CONFERENCE_CREATE;
    errors = null;

    constructor(public payload: CreateTanAppointmentDTO) {}
}

export class CreateTanAppointmentCompletedAction implements AppointmentAction {
    readonly type = APPOINTMENT_CREATE_TAN_APPOINTMENT_COMPLETED;
    errors = null;

    constructor(public payload: CreateTanAppointmentDTO) {}
}

export class CreateTanAppointmentFailedAction implements AppointmentAction {
    readonly type = APPOINTMENT_CREATE_TAN_APPOINTMENT_FAILED;
    payload = null;

    constructor(public errors: string[]) {}
}

export class CancelAppointmentCompletedAction implements AppointmentAction {
    readonly type = APPOINTMENT_CANCEL_COMPLETED;
    errors = null;

    constructor(public payload: CancelAppointmentDTO) {}
}

export class CancelAppointmentFailedAction implements AppointmentAction {
    readonly type = APPOINTMENT_CANCEL_FAILED;
    payload = null;

    constructor(public errors: string[]) {}
}
